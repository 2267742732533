import Vue from "vue";
const vm = Vue.prototype;
import axios from "axios";
import store from "../store";
import i18n from "../i18n";

import sysConfig from "../utils/static-config.js";

import router from "../router";

interceptor();

const $get = async (url, params) => {
  try {
    params = params || {};
    return (await axios.get(url, params)).data;
  } catch {
    return null;
  }
};

const $post = async (url, param) => {
  try {
    return (await axios.post(url, param)).data;
  } catch (e) {
    return null;
  }
};

const $put = async (url, param) => {
  try {
    return (await axios.put(url, param)).data;
  } catch {
    return null;
  }
};

const $delete = async (url, param) => {
  try {
    return (await axios.delete(url, param)).data;
  } catch {
    return null;
  }
};

vm.$axios = { $get, $post, $put, $delete, ...axios };

function interceptor() {
  axios.defaults.baseURL = `${sysConfig.serverUrl}${sysConfig.apiVersion}`;

  axios.interceptors.request.use(
    (req) => {
      const token = vm.$cookies.get("token");
      if (token) req.headers.Authorization = `bearer ${token}`;
      req.headers["Accept-Language"] = i18n.locale;
      return req;
    },

    (config) => {
      const token = vm.$cookies.get("token");

      if (token) config.headers.Authorization = `bearer ${token}`;

      return config;
    },

    (error) => {
      onError(error?.response?.status);

      return Promise.reject(error);
    }
  );

  axios.interceptors.response.use(
    (response) => response,

    (thrown) => {
      if (axios.isCancel(thrown)) {
        //
      } else {
        onError(thrown?.response?.status);
        showError(thrown);
      }

      return Promise.reject(thrown);
    }
  );
}

function onError(status) {
  if ((status == 401 || status == 403) && router.currentRoute.path != "/") {
    vm.$cookies.remove("token");
    router.replace("/");
  }
}

function showError(error) {
  let msg = null;
  if (error?.response) {
    const res = error.response || {};
    const data = res.data || {};

    msg = i18n.t("Internal error. (code-{0})", { 0: res.status });

    if (data.detail) msg = data.detail;
  } else {
    msg = i18n.t("No connection to server");
  }

  store.dispatch("SET_SNACKBAR", { text: msg, color: "error" });
}
