<script>
import { mapActions } from "vuex";
import CardTemplate from "../components/card-template.vue";

export default {
  name: "Register",

  components: {
    CardTemplate,
  },

  data: () => ({
    register: {
      first_name: null,
      last_name: null,
      category: null,
    },
    categorys: [],

    isLoading: true,
  }),

  created() {
    this.init();
  },

  methods: {
    ...mapActions("auth", ["REGISTER_PROFILE"]),

    async init() {
      this.isLoading = true;
      let data = (await this.$axios.$get("recipes/category/")) || {};
      this.categorys = this.$array(data?.results);
      this.isLoading = false;
    },

    async onSend() {
      if (this.$refs.form.validate()) {
        this.isLoading = true;
        const params = { ...this.register };
        const status = await this.REGISTER_PROFILE(params);
        if (status) {
          //
        }

        this.isLoading = false;
      }
    },
  },
};
</script>

<template>
  <card-template
    :title="$t('Registration')"
    :description="$t('Fill in information about yourself')"
  >
    <v-form
      :readonly="isLoading"
      @submit.prevent="() => false"
      ref="form"
      lazy-validation
      class="pt-5"
    >
      <div class="field-label pt-0">{{ $t("Name") }}</div>
      <v-text-field
        autofocus
        autocomplete="off"
        v-model="register.first_name"
        outlined
        required
        :rules="[$rules.required]"
      />

      <div class="field-label pt-0">{{ $t("Surname") }}</div>
      <v-text-field
        autocomplete="off"
        v-model="register.last_name"
        outlined
        required
        :rules="[$rules.required]"
      />

      <div class="field-label pt-0">{{ $t("Position") }}</div>
      <v-select
        autocomplete="off"
        v-model="register.category"
        outlined
        required
        :items="categorys"
        item-text="name"
        item-value="id"
        :rules="[$rules.required]"
      />

      <v-btn
        @click="onSend"
        :loading="isLoading"
        color="primary"
        class="w-100 btn-accent"
      >
        {{ $t("Continue") }}
      </v-btn>
    </v-form>
  </card-template>
</template>

<style lang="scss" scoped>
.field-label {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 100%;

  padding-top: 24px;
  padding-bottom: 8px;
}
</style>
