<script>
import CardTemplate from "../components/card-template.vue";

export default {
  name: "SuccessSent",

  components: {
    CardTemplate,
  },
};
</script>

<template>
  <card-template
    autoHeight
    noShadow
    :width="935"
    :title="$t('Sending an e-prescription')"
  >
    <div class="text-center" style="padding: 41px 0">
      <img src="/img/svg/progress-3.svg" alt="" />
    </div>

    <div class="text-center">
      <img src="/img/svg/success-sent.svg" alt="" />
    </div>

    <div
      class="text-center"
      style="padding-bottom: 32px; max-width: 535px; margin: 0 auto"
      v-html="
        $t(
          'The prescription was successfully sent to the patient {0} to the number {1}',
          {
            0: $route.params.name,
            1: `<span class='text-nowrap'>${$tel($route.params.phone)}</span>`,
          }
        )
      "
    />

    <div style="max-width: 374px; margin: 0 auto">
      <v-btn to="/search-drugs" color="primary" class="w-100 btn-accent">
        {{ $t("Return to home page") }}
      </v-btn>
    </div>
  </card-template>
</template>

<style lang="scss" scoped>
//
</style>
