import Vue from "vue";

const vm = Vue.prototype;

import router from "@/router";

export const state = () => ({
  user: {},
});

export const mutations = {
  SET_USER(state, user) {
    state.user = user || {};
  },
};

export const actions = {
  async REGISTER(ctx, { username }) {
    const status = await vm.$axios.$post("recipes/register/", { username });
    return status != null;
  },

  async REGISTER_PROFILE(ctx, params) {
    const url = "recipes/register-profile";
    const data = (await vm.$axios.$post(url, params)) || {};
    if (data.status == 1) router.replace("/search-drugs");
  },

  async LOGIN({ commit }, { username, password }) {
    let status = false;

    const params = { username, password };
    const data = await vm.$axios.$post("recipes/login/", params);

    if (data?.token) {
      vm.$cookies.set("token", data.token);
      await commit("SET_USER", data.user);
      router.replace(data.user.complete == 1 ? "/search-drugs" : "/register");
      status = true;
    }

    return status;
  },

  async INIT_USER({ commit, dispatch }, payload) {
    let status = false;

    if (payload?.loader != false) {
      dispatch("SET_LOADING", true, { root: true });
    }

    const token = vm.$cookies.get("token");
    if (token) {
      const user = await vm.$axios.$get("recipes/login/");

      if (user?.id) {
        commit("SET_USER", user);
        status = true;
      } else {
        vm.$cookies.remove("token");
        if (router.currentRoute.path != "/") {
          router.replace("/");
        }
      }
    }

    if (payload?.loader != false) {
      dispatch("SET_LOADING", false, { root: true });
    }

    return status;
  },

  LOGOUT({ commit }) {
    vm.$cookies.set("token", null);
    commit("SET_USER", {});
  },
};

export const getters = {
  GET_USER: (state) => state.user,
};
