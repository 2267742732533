<script>
import { mapActions } from "vuex";

import CardTemplate from "../components/card-template.vue";

import ConfirmSms from "../components/confirm-sms.vue";

export default {
  name: "SendSms",

  components: {
    CardTemplate,
    ConfirmSms,
  },

  data: () => ({
    phone: null,

    isLoading: false,

    isSended: false,
  }),

  methods: {
    ...mapActions("auth", ["REGISTER"]),

    async onSend() {
      if (this.$refs.form.validate()) {
        this.isLoading = true;
        const params = { username: `998${this.phone}` };
        const status = await this.REGISTER(params);
        this.isSended = !!status;
        this.isLoading = false;
      }
    },
  },
};
</script>

<template>
  <div>
    <confirm-sms @resend="isSended = false" v-if="isSended" :phone="phone" />

    <card-template
      v-else
      :title="$t('Welcome')"
      :description="
        $t('Enter your phone number in order to enter your personal account')
      "
    >
      <v-form
        :readonly="isLoading"
        @submit.prevent="() => false"
        ref="form"
        lazy-validation
      >
        <div class="field-label">{{ $t("Phone number") }}</div>
        <v-text-field
          autofocus
          autocomplete="off"
          v-model="phone"
          outlined
          required
          :rules="[$rules.required, $rules.phone]"
          v-inputmask="$masks.phone"
          @keyup.enter="onSend"
        />

        <v-btn
          @click="onSend"
          :loading="isLoading"
          color="primary"
          class="w-100 btn-accent"
        >
          {{ $t("Send SMS") }}
        </v-btn>
      </v-form>
    </card-template>
  </div>
</template>

<style lang="scss" scoped>
.field-label {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 100%;

  padding-top: 24px;
  padding-bottom: 8px;
}
</style>
