<script>
import { debounce } from "debounce";

import { mapActions, mapGetters } from "vuex";

export default {
  props: {
    autofocus: {
      type: Boolean,
      default: () => false,
    },
  },

  data: () => ({
    isLoading: false,
    searchValue: null,
    items: [],
    hideNoData: true,
  }),

  computed: {
    ...mapGetters("send-prescription", ["GET_DATA"]),

    model: {
      get: () => null,
      set(v) {
        this.SET_SELECTED(v);
      },
    },

    isActive: (vm) => (v) => vm.GET_DATA.recipes.some((x) => x.drug == v),
  },

  watch: {
    searchValue(value) {
      this.searchApi(value);
    },
  },

  methods: {
    ...mapActions("send-prescription", ["SEARCH", "SET_SELECTED"]),

    searchApi: debounce(async function (value) {
      if ((this.searchValue || "").trim()) {
        // if (this.isLoading) return;
        this.isLoading = true;
        this.hideNoData = true;
        this.items = [];
        const data = await this.SEARCH({ value });
        this.isLoading = false;
        this.items = data;
        this.hideNoData = !this.items.length;
      } else this.items = [];
    }, 300),

    onBlur() {
      this.items = [];
      this.hideNoData = true;
    },

    onNotFound() {
      this.SET_SELECTED({ name: this.searchValue, id: this.$uuid() });
    },
  },
};
</script>

<template>
  <v-autocomplete
    no-filter
    :loading="isLoading"
    :autofocus="autofocus"
    v-model="model"
    :items="items"
    append-icon=""
    :search-input.sync="searchValue"
    @keyup.enter="searchApi(searchValue)"
    item-text="name"
    item-value="id"
    :placeholder="$t('Enter the name of the medicine')"
    return-object
    outlined
    hide-details
    ref="autocomplete"
    :hide-no-data="hideNoData"
    @blur="onBlur"
  >
    <template v-slot:selection></template>

    <template v-slot:item="data">
      <v-list-item
        :disabled="isActive(data.item.id)"
        v-bind="data.attrs"
        v-on="data.on"
        active-class=""
        class="autocomplate-item"
      >
        <v-list-item-content>
          <v-list-item-title v-text="data.item.name" />
        </v-list-item-content>
      </v-list-item>
    </template>

    <template v-slot:append>
      <v-btn @click="onNotFound" v-if="searchValue" text small color="red">
        {{ $t("Not found") }}
      </v-btn>
    </template>
  </v-autocomplete>
</template>

<style lang="scss" scoped>
//
</style>
