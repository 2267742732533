<script>
import { mapActions } from "vuex";

import CardTemplate from "./card-template.vue";

export default {
  components: {
    CardTemplate,
  },

  props: {
    phone: {
      type: String,
      required: true,
    },
  },

  data: () => ({
    length4Rule: (v) => (v?.length == 4 ? true : ""),

    smscode: null,

    isLoading: false,
    isReLoading: false,
  }),

  methods: {
    ...mapActions("auth", ["LOGIN"]),

    async onSend() {
      if (this.$refs.form.validate()) {
        this.isLoading = true;
        const params = { username: `998${this.phone}`, password: this.smscode };
        await this.LOGIN(params);
        this.isLoading = false;
      }
    },

    async onReSend() {
      this.$emit("resend");
    },
  },
};
</script>

<template>
  <div>
    <card-template
      :title="$t('Confirmation')"
      :description="
        $t('A 4-digit code has been sent to {0}, please enter them', {
          0: `+998${phone}`,
        })
      "
    >
      <v-form @submit.prevent="() => false" ref="form" lazy-validation>
        <div class="field-label">
          {{ $t("Enter a code") }}
        </div>
        <v-text-field
          autofocus
          autocomplete="off"
          v-model="smscode"
          outlined
          required
          v-inputmask="{
            mask: '9',
            repeat: 4,
            greedy: false,
            showMaskOnHover: false,
          }"
          :rules="[$rules.required, length4Rule]"
          @keyup.enter="onSend"
        />

        <v-btn
          @click="onSend"
          :loading="isLoading"
          color="primary"
          class="w-100 btn-accent"
        >
          {{ $t("Send SMS") }}
        </v-btn>

        <div class="pt-3 text-center">
          Не получили код?
          <v-btn class="px-1" @click="onReSend" text color="#7C8087">
            Отправить снова
          </v-btn>
        </div>
      </v-form>
    </card-template>
  </div>
</template>

<style lang="scss" scoped>
.field-label {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 100%;

  padding-top: 24px;
  padding-bottom: 8px;
}
</style>
