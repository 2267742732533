var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('card-template',{attrs:{"autoHeight":"","noShadow":"","width":935,"title":_vm.$t('Sending an e-prescription')}},[_c('div',{staticClass:"text-center",staticStyle:{"padding":"41px 0"}},[_c('img',{attrs:{"src":"/img/svg/progress-3.svg","alt":""}})]),_c('div',{staticClass:"text-center"},[_c('img',{attrs:{"src":"/img/svg/success-sent.svg","alt":""}})]),_c('div',{staticClass:"text-center",staticStyle:{"padding-bottom":"32px","max-width":"535px","margin":"0 auto"},domProps:{"innerHTML":_vm._s(
      _vm.$t(
        'The prescription was successfully sent to the patient {0} to the number {1}',
        {
          0: _vm.$route.params.name,
          1: ("<span class='text-nowrap'>" + (_vm.$tel(_vm.$route.params.phone)) + "</span>"),
        }
      )
    )}}),_c('div',{staticStyle:{"max-width":"374px","margin":"0 auto"}},[_c('v-btn',{staticClass:"w-100 btn-accent",attrs:{"to":"/search-drugs","color":"primary"}},[_vm._v(" "+_vm._s(_vm.$t("Return to home page"))+" ")])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }