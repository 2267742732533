import Vue from "vue";
const vm = Vue.prototype;

export default (to, from, next) => {
  if (to.meta.auth) {
    const url = from.path == "/login" ? false : "/login";
    const token = vm.$cookies.get("token");
    next(token ? true : url);
  } else {
    next();
  }
};
