import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

// const vm = Vue.prototype;

export default new Vuex.Store({
  modules: getModules(),

  state: {
    snackbar: {
      text: false,
      color: "success",
      isShow: false,
    },

    size: initSize(),

    loading: false,
  },

  mutations: {
    SET_SNACKBAR(state, snackbar) {
      snackbar.isShow = true;
      state.snackbar = snackbar;
    },

    SET_SIZE(state, { width = 0, height = 0 }) {
      state.size = { width, height };
    },

    SET_LOADING(state, payload) {
      state.loading = payload;
    },
  },

  actions: {
    SET_SNACKBAR({ commit }, payload) {
      commit("SET_SNACKBAR", payload);
    },

    SET_SIZE({ commit }, { width, height }) {
      commit("SET_SIZE", { width, height });
    },

    async INIT_STATES({ dispatch, commit, rootState }) {
      commit("SET_LOADING", true);

      let status = false;

      if (!rootState.auth.user.id) {
        status = await dispatch("auth/INIT_USER", { loader: false });
      }

      if (status || rootState.auth.user.id) {
        await dispatch("send-prescription/INIT");
      }

      commit("SET_LOADING", false);
    },

    SET_LOADING({ commit }, payload) {
      commit("SET_LOADING", payload);
    },
  },

  getters: {
    GET_SIZE: (state) => state.size,

    GET_SNACKBAR: (state) => state.snackbar,

    GET_LOADING: (state) => state.loading,
  },
});

function getModules() {
  const modulesCtx = require.context("./", true, /[A-Za-z0-9-_,\s]+\.js$/i);

  const modules = {};
  // const ignores = [];
  //

  modulesCtx.keys().forEach((key) => {
    if (key != "./index.js" /*&& ignores.indexOf(key) < 0*/) {
      const name = key.replace("./", "").replace(".js", "");
      let module = modulesCtx(key);
      module.namespaced = true;
      modules[name] = module;
    }
  });

  return modules;
}

function initSize() {
  if (window) {
    return {
      width: window.innerWidth,
      height: window.innerHeight,
    };
  } else {
    return {
      width: 992,
      height: 992,
    };
  }
}
