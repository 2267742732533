import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import "roboto-fontface/css/roboto/roboto-fontface.css";
import "@mdi/font/css/materialdesignicons.css";

import "./filters";

import "./plugins/axios.js";

import "./assets/style.scss";

import VueCookies from "vue-cookies";
Vue.use(VueCookies);

Vue.config.productionTip = false;

import browserStore from "./service/browser-store.js";
Vue.prototype.$browserStore = browserStore;

import isMobile from "./service/isMobile.js";
Vue.prototype.$isMobile = isMobile();
import checkers from "./service/checkers.js";
Vue.prototype.$array = checkers.array;
Vue.prototype.$uniarray = checkers.uniarray;
Vue.prototype.$number = checkers.number;
Vue.prototype.$uuid = checkers.uuid;

import rules from "./service/rules.js";
Vue.prototype.$rules = rules;

import "./plugins/inputmask.js";
import masks from "./service/masks.js";
import i18n from "./i18n";
Vue.prototype.$masks = masks;

new Vue({
  router,
  store,
  vuetify,
  i18n,
  beforeMount() {
    this.$store.dispatch("INIT_STATES");
  },
  render: (h) => h(App),
}).$mount("#app");
