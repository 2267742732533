import Vue from "vue";

const vm = Vue.prototype;

export const state = () => ({
  items: [],
});

export const mutations = {
  SET_ITEMS(state, items) {
    state.items = vm.$array(items);
  },
};

export const actions = {
  async INIT({ commit }) {
    const data = await vm.$axios.$get(`recipes/history/`);
    const results = vm.$array(data?.results).map((x) => {
      x.patient = x.patient || {};
      return x;
    });

    commit("SET_ITEMS", results);
  },
};

export const getters = {
  GET_ITEMS: (state) => state.items,
};
