<script>
import { mapActions, mapGetters } from "vuex";

import CardTemplate from "../components/card-template.vue";

import SearchAutocomplate from "../components/search-autocomplate.vue";

import doses from "../utils/doses.json";
import partOfDays from "../utils/part-of-days.json";
import usages from "../utils/usages.json";
import periods from "../utils/periods.json";

export default {
  name: "SearchDrugs",

  components: {
    CardTemplate,
    SearchAutocomplate,
  },

  data: () => ({
    //
  }),

  computed: {
    ...mapGetters("send-prescription", ["GET_DATA"]),

    getDoses() {
      return doses.map((x) => {
        x.full_name = this.$t(x.name);
        return x;
      });
    },

    getPartOfDays() {
      return partOfDays.map((x) => {
        x.full_name = this.$t(x.name);
        return x;
      });
    },

    getUsages() {
      return usages.map((x) => {
        x.full_name = this.$t(x.name);
        return x;
      });
    },

    getPeriods() {
      return periods.map((x) => {
        x.full_name = this.$t(x.name);
        return x;
      });
    },
  },

  created() {
    this.GET_DATA.isValid = false;
    this.onChanged({ some: this.GET_DATA });
  },

  methods: {
    ...mapActions("send-prescription", ["REMOVE", "CHANGE"]),

    onRemove({ id }) {
      this.REMOVE({ id });
    },

    onChanged({ item, some }) {
      this.CHANGE({ item, some });
    },

    onNext() {
      if (this.$refs.form.validate()) {
        this.GET_DATA.isValid = true;
        this.onChanged({ some: this.GET_DATA });

        this.$nextTick(() => {
          this.$router.push("/patient-info");
        });
      }
    },

    beforeEnter(el) {
      el.style.opacity = 0;
      el.style.height = 0;
      el.style.overflow = "hidden";
    },

    enter(el, done) {
      if (process.browser) {
        const Velocity = require("velocity-animate");
        const delay = el.dataset.index * 150;
        const height = el?.firstChild.offsetHeight || 268;

        setTimeout(() => {
          Velocity(
            el,
            { opacity: 1, height: `${height}px` },
            { complete: done }
          );
        }, delay);
      }
    },

    leave(el, done) {
      if (process.browser) {
        const Velocity = require("velocity-animate");
        const delay = el.dataset.index * 150;
        setTimeout(() => {
          Velocity(el, { opacity: 0, height: 0 }, { complete: done });
        }, delay);
      }
    },
  },
};
</script>

<template>
  <div>
    <card-template
      autoHeight
      noShadow
      :width="935"
      :title="$t('Sending an e-prescription')"
    >
      <div class="text-center" style="padding: 41px 0">
        <img
          :src="`/img/svg/progress-${GET_DATA.recipes.length ? 1 : 0}.svg`"
          alt=""
        />
      </div>

      <search-autocomplate autofocus />

      <v-form @submit.prevent="() => false" ref="form" lazy-validation>
        <transition-group
          @before-enter="beforeEnter"
          @enter="enter"
          @leave="leave"
          name="staggered-fade"
          :css="false"
        >
          <div v-for="item in GET_DATA.recipes" :key="item.drug">
            <div style="padding-top: 24px">
              <div class="drug-card">
                <v-row>
                  <v-col cols="12">
                    <div class="d-flex align-center">
                      <div class="drug-name" v-text="item.name" />

                      <div
                        @click="onRemove({ id: item.drug })"
                        v-ripple
                        class="close-btn"
                      >
                        <v-icon> mdi-close </v-icon>
                      </div>
                    </div>
                  </v-col>

                  <v-col cols="12" md="4">
                    <v-select
                      v-model="item.taking"
                      :placeholder="$t('Number of receptions')"
                      :items="['0.5', '1', '2', '3', '4', '5', '6', '7']"
                      outlined
                      required
                      hide-details
                      :rules="[$rules.required]"
                      @change="onChanged({ item })"
                    />
                  </v-col>
                  <v-col cols="12" md="4">
                    <v-select
                      v-model="item.dose"
                      :placeholder="$t('Dose per day')"
                      :items="getDoses"
                      item-text="full_name"
                      item-value="value"
                      outlined
                      required
                      hide-details
                      :rules="[$rules.required]"
                      @change="onChanged({ item })"
                    />
                  </v-col>
                  <v-col cols="12" md="4">
                    <v-select
                      v-model="item.part_of_day"
                      :placeholder="$t('Time')"
                      :items="getPartOfDays"
                      item-text="full_name"
                      item-value="value"
                      outlined
                      required
                      hide-details
                      :rules="[$rules.required]"
                      @change="onChanged({ item })"
                    />
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-select
                      v-model="item.usage"
                      :placeholder="$t('Application')"
                      :items="getUsages"
                      item-text="full_name"
                      item-value="value"
                      outlined
                      required
                      hide-details
                      :rules="[$rules.required]"
                      @change="
                        onChanged({
                          item,
                        })
                      "
                    />
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-select
                      v-model="item.period"
                      :placeholder="$t('Period')"
                      :items="getPeriods"
                      item-text="full_name"
                      item-value="value"
                      outlined
                      required
                      hide-details
                      :rules="[$rules.required]"
                      @change="onChanged({ item })"
                    />
                  </v-col>
                </v-row>
              </div>
            </div>
          </div>
        </transition-group>
        <div class="mt-24" />

        <v-textarea
          v-model="GET_DATA.diagnose"
          :placeholder="$t('Write the diagnosis of the patient\'s illness')"
          autocomplete="off"
          outlined
          required
          :rules="[$rules.required]"
          @blur="onChanged({ some: GET_DATA })"
        />

        <div class="d-flex">
          <div style="max-width: 193px" class="w-100">
            <v-btn
              to="/"
              color="rgba(124, 128, 135, 0.2)"
              class="btn-accent w-100"
            >
              <span style="color: #7c8087">
                {{ $t("Cancellation") }}
              </span>
            </v-btn>
          </div>
          <v-spacer />
          <div style="max-width: 193px" class="w-100">
            <v-btn
              :disabled="!GET_DATA.recipes.length"
              @click="onNext"
              color="primary"
              class="btn-accent w-100"
            >
              {{ $t("Further") }}
            </v-btn>
          </div>
        </div>
      </v-form>
    </card-template>
  </div>
</template>

<style lang="scss" scoped>
.drug-card {
  padding: 24px;
  background: #f9fafb;
  border: 2px solid #3d46e9;
  box-sizing: border-box;
  border-radius: 8px;
}

.mt-16-px {
  margin-top: 16px;
}

.drug-name {
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 120%;

  flex: 1;
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
</style>
