<script>
import { mapGetters, mapActions } from "vuex";

import doses from "../utils/doses.json";
import usages from "../utils/usages.json";
import periods from "../utils/periods.json";
import partOfDays from "../utils/part-of-days.json";

export default {
  computed: {
    ...mapGetters("recipes-history", ["GET_ITEMS"]),

    getDoses() {
      return doses.map((x) => {
        x.full_name = this.$t(x.name);
        return x;
      });
    },
    getUsages() {
      return usages.map((x) => {
        x.full_name = this.$t(x.name);
        return x;
      });
    },

    getPartOfDays() {
      return partOfDays.map((x) => {
        x.full_name = this.$t(x.name);
        return x;
      });
    },

    getPeriods() {
      return periods.map((x) => {
        x.full_name = this.$t(x.name);
        return x;
      });
    },
  },

  created() {
    this.INIT();
  },

  methods: {
    ...mapActions("recipes-history", ["INIT"]),

    getDoseName(value) {
      const dose = this.getDoses.find((x) => x.value == value) || {};
      return dose.full_name;
    },

    getUseName(value) {
      const use = this.getUsages.find((x) => x.value == value) || {};
      return use.full_name;
    },

    getPeriodName(value) {
      const period = this.getPeriods.find((x) => x.value == value) || {};
      return period.full_name;
    },

    getPartOfDayName(value) {
      const partOfDay = this.getPartOfDays.find((x) => x.value == value) || {};
      return partOfDay.full_name;
    },
  },
};
</script>

<template>
  <div class="card">
    <div class="d-flex justify-space-between">
      <div>
        <p class="title-recipe">
          {{ $t("Recipe history") }}
        </p>
      </div>
    </div>

    <div class="forms" v-for="item in GET_ITEMS" :key="item.id">
      <div class="pa-4 pb-0 d-flex justify-space-between">
        <div>
          <p class="grey--text">№ {{ item.doctor }}</p>
        </div>
        <!-- <div>
          <p class="title-view">
            {{ new Date().toLocaleString().slice(0, 10) }}
          </p>
        </div> -->
      </div>
      <v-divider></v-divider>

      <v-container class="pa-4">
        <v-row class="mb-2">
          <v-col cols="12" sm="6" class="px-3">
            <div class="mb-1">
              <p class="title-view">{{ $t("First name") }}</p>
            </div>
            <div class="recipe-view">
              {{ item.patient.name }}
            </div>
          </v-col>
          <v-col cols="12" sm="6" class="px-3">
            <div class="mb-1">
              <p class="title-view">{{ $t("Number") }}</p>
            </div>
            <div class="recipe-view">
              {{ $tel(item.patient.phone) }}
            </div>
          </v-col>
        </v-row>

        <v-row class="my-2" v-for="recipe in item.recipes" :key="recipe.id">
          <v-col cols="12" class="py-0">
            <v-divider v-if="item.recipes.length > 1" />
          </v-col>
          <v-col cols="12" class="px-3">
            <div class="recipe-view">{{ recipe.name }}</div>
          </v-col>
          <v-col cols="12" sm="4" class="px-3">
            <div class="recipe-view">
              {{ recipe.taking ? `${recipe.taking} ${$t("Reception")}` : "" }}
            </div>
          </v-col>
          <v-col cols="12" sm="4" class="px-3">
            <div class="recipe-view">
              {{ getDoseName(recipe.dose) }}
            </div>
          </v-col>
          <v-col cols="12" sm="4" class="px-3">
            <div class="recipe-view">
              {{ getPartOfDayName(recipe.part_of_day) }}
            </div>
          </v-col>
          <v-col cols="12" sm="6" class="px-3">
            <div class="recipe-view">{{ getUseName(recipe.usage) }}</div>
          </v-col>
          <v-col cols="12" sm="6" class="px-3">
            <div class="recipe-view">{{ getPeriodName(recipe.period) }}</div>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <div v-if="!GET_ITEMS.length">
      <div class="text-center pt-15">
        <img width="166" src="/img/no-data.png" alt="" />

        <div
          class="pt-5"
          v-text="$t('Your e-prescription history is empty.')"
        />
        <div v-text="$t('Add a new recipe.')" />

        <v-btn
          to="/search-drugs"
          color="primary"
          class="w-100 btn-accent mt-5"
          style="max-width: 374px"
        >
          {{ $t("Add e-recipe") }}
        </v-btn>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.card {
  width: 100%;
  max-width: 935px;
  margin: 0 auto;
  padding: 24px 24px 40px 24px;
  background: #fff;
  margin-top: 53px;
  margin-bottom: 73px;
  border-radius: 15px;

  @media screen and(max-width:425px) {
    padding: 15px;
  }
}

.title-recipe {
  font-size: 24px;
  color: #1d293f;
  font-weight: bold;
  margin-bottom: 0;
}

.title-view {
  font-size: 17px;
  color: #1d293f;
  font-weight: bold;
  margin-bottom: 0;
}

.forms {
  margin-top: 30px;
  background: #f4f5f7;
  border-radius: 8px;

  .recipe-view {
    width: 100%;
    height: 48px;
    background: #fff;
    color: #1d293f;
    font-size: 18px;
    font-weight: 700;
    border-radius: 8px;
    border: none;
    padding: 12px 24px;
  }
}
</style>
