<script>
import { debounce } from "debounce";

import toolbar from "./components/toolbar.vue";

export default {
  name: "App",

  components: { toolbar },

  data: () => ({
    //
  }),

  computed: {
    snackbar: (vm) => vm.$store.getters.GET_SNACKBAR,
    loading: (vm) => vm.$store.getters.GET_LOADING,
  },

  beforeMount() {
    this.onResize();
    window.addEventListener("resize", this.onResize);
  },

  destroyed() {
    window.removeEventListener("resize", this.onResize);
  },

  methods: {
    onResize: debounce(function () {
      const width = window.innerWidth || 0;
      const height = window.innerHeight || 0;

      this.$store.dispatch("SET_SIZE", { width, height });
    }, 800),
  },
};
</script>

<template>
  <v-app>
    <v-dialog persistent :value="loading" max-width="400">
      <div style="padding: 20px 20px 12px; background-color: white">
        <v-progress-linear color="#3d46e9" indeterminate rounded height="6" />

        <div class="pt-5 text-center" v-text="$t('Loading...')" />
      </div>
    </v-dialog>

    <v-main v-if="!loading">
      <v-container>
        <toolbar />
      </v-container>
      <router-view />
    </v-main>

    <v-snackbar
      top
      v-model="snackbar.isShow"
      :color="snackbar.color"
      :timeout="8500"
    >
      <div v-html="snackbar.text" />
      <template slot="action">
        <v-btn
          icon
          :title="$t('Close')"
          class="textTansform_none"
          dark
          text
          @click="snackbar.isShow = false"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </v-app>
</template>

<style lang="scss">
//
</style>
