<script>
import { mapGetters } from "vuex";
export default {
  data: () => ({
    drawer: false,
  }),

  computed: {
    items: (vm) => [
      // {
      //   name: vm.$t("About"),
      //   action: () => {},
      //   text: true,
      // },
      {
        name: vm.$t("Search for medicines"),
        url: "/search-drugs",
        class: "mx-5",
        text: true,
      },
      {
        name: vm.$t("Recipe history"),
        url: "/recipes-history",
        text: true,
        auth: true,
      },
      // {
      //   name: vm.$t("My account"),
      //   url: "/account",
      //   outlined: true,
      // },
    ],

    ...mapGetters("auth", ["GET_USER"]),
    user: (vm) => vm.GET_USER,
  },

  methods: {
    isAuth(item) {
      if (item?.auth) return !!this.user?.id;
      else return true;
    },
  },
};
</script>

<template>
  <div>
    <v-toolbar class="toolbar" color="transparent" elevation="0">
      <router-link to="/">
        <v-img class="logo" src="/img/svg/logo.svg" alt="" />
      </router-link>

      <v-spacer />

      <template v-if="$vuetify.breakpoint.mdAndUp">
        <template v-for="(item, key) in items">
          <template v-if="isAuth(item)">
            <v-btn
              v-if="item.action"
              @click="item.action"
              :outlined="item.outlined"
              :text="item.text"
              class="btn-accent"
              :class="item.class"
              :key="key"
            >
              {{ item.name }}
            </v-btn>
            <v-btn
              v-else
              :to="item.url"
              :outlined="item.outlined"
              :text="item.text"
              class="btn-accent"
              :class="item.class"
              :key="key"
            >
              {{ item.name }}
            </v-btn>
          </template>
        </template>
      </template>

      <template v-else>
        <v-app-bar-nav-icon @click.stop="drawer = !drawer" />
      </template>
    </v-toolbar>

    <v-navigation-drawer
      v-if="$vuetify.breakpoint.smAndDown"
      v-model="drawer"
      fixed
      right
    >
      <v-list nav dense>
        <div class="py-5 px-10">
          <v-img src="/img/svg/logo.svg" alt="" />
        </div>

        <v-divider class="mb-5" />

        <template v-for="(item, key) in items">
          <v-list-item v-if="item.action" @click="item.action" :key="key">
            <v-list-item-title v-text="item.name" />
          </v-list-item>
          <v-list-item v-else :to="item.url" :key="key">
            <v-list-item-title v-text="item.name" />
          </v-list-item>
        </template>
      </v-list>
    </v-navigation-drawer>
  </div>
</template>

<style lang="scss" scoped>
.logo {
  max-width: 220px;
}
</style>
