var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('card-template',{attrs:{"title":_vm.$t('Confirmation'),"description":_vm.$t('A 4-digit code has been sent to {0}, please enter them', {
        0: ("+998" + _vm.phone),
      })}},[_c('v-form',{ref:"form",attrs:{"lazy-validation":""},on:{"submit":function($event){$event.preventDefault();return (function () { return false; }).apply(null, arguments)}}},[_c('div',{staticClass:"field-label"},[_vm._v(" "+_vm._s(_vm.$t("Enter a code"))+" ")]),_c('v-text-field',{directives:[{name:"inputmask",rawName:"v-inputmask",value:({
          mask: '9',
          repeat: 4,
          greedy: false,
          showMaskOnHover: false,
        }),expression:"{\n          mask: '9',\n          repeat: 4,\n          greedy: false,\n          showMaskOnHover: false,\n        }"}],attrs:{"autofocus":"","autocomplete":"off","outlined":"","required":"","rules":[_vm.$rules.required, _vm.length4Rule]},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.onSend.apply(null, arguments)}},model:{value:(_vm.smscode),callback:function ($$v) {_vm.smscode=$$v},expression:"smscode"}}),_c('v-btn',{staticClass:"w-100 btn-accent",attrs:{"loading":_vm.isLoading,"color":"primary"},on:{"click":_vm.onSend}},[_vm._v(" "+_vm._s(_vm.$t("Send SMS"))+" ")]),_c('div',{staticClass:"pt-3 text-center"},[_vm._v(" Не получили код? "),_c('v-btn',{staticClass:"px-1",attrs:{"text":"","color":"#7C8087"},on:{"click":_vm.onReSend}},[_vm._v(" Отправить снова ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }