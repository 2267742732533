import Vue from "vue";
const vm = Vue.prototype;
import router from "../router.js";

import axios from "axios";
const CancelToken = axios.CancelToken;
let source = null;

export const state = () => ({
  data: initData(),
});

export const mutations = {
  INIT(state, { data }) {
    state.data = data;
    setStore(state.data);
  },

  SET_SELECTED(state, payload) {
    const items = vm.$array(state.data?.recipes);
    if (items.findIndex((x) => x.drug == payload.id) < 0) {
      state.data.recipes.unshift({
        drug: payload.id,
        name: payload.name,
        dose: null,
        recept: null,
        period: null,
        usage: null,
      });
    }

    setStore(state.data);
  },

  REMOVE(state, { id }) {
    state.data.recipes = state.data.recipes.filter((x) => x.drug != id);
    setStore(state.data);
  },

  CHANGE(state, { item, some }) {
    if (some) {
      state.data = some;
    } else {
      state.data.recipes = state.data.recipes.map((x) => {
        if (x.drug == item.drug) return item;
        else return x;
      });
    }

    setStore(state.data);
  },
};

export const actions = {
  INIT({ commit }) {
    const data = getStore();
    if (data) commit("INIT", { data });
  },

  async SEARCH(ctx, { value }) {
    let results = [];

    source?.cancel();
    source = CancelToken.source();

    if (`${value || ""}`.length > 1) {
      const data = await vm.$axios.$get(`recipes/drugs?search=${value}`, {
        progress: false,
        cancelToken: source.token,
      });

      results = vm.$array(data?.results);
    }

    return results;
  },

  async SET_SELECTED({ commit }, value) {
    commit("SET_SELECTED", value);
  },

  REMOVE({ commit }, { id }) {
    commit("REMOVE", { id });
  },

  CHANGE({ commit }, { item, some }) {
    commit("CHANGE", { item, some });
  },

  async SEND({ commit }, { data }) {
    const params = JSON.parse(JSON.stringify(data));
    params.recipes = params.recipes.map((x) => {
      x.drug = vm.$number(x.drug) || null;
      return x;
    });

    const status = await vm.$axios.$post(`/recipes/recipe/`, params);
    if (status) {
      const name = data.patient.name;
      const phone = data.patient.phone;
      const params = { data: initData() };
      commit("INIT", params);

      router.push(`/success-sent/${name}/${phone}`);
    }

    return status != null;
  },
};

export const getters = {
  GET_DATA: (state) => state.data,
};

function setStore(params, key = "data_items") {
  vm.$browserStore.setSession(key, params);
}

function getStore(key = "data_items") {
  return vm.$browserStore.getSession(key);
}

function initData() {
  return {
    recipes: [
      // {
      //   drug: null,
      //   name: null,
      //   dose: null,
      //   recept: null,
      //   period: null,
      //   usage: null,
      //taking:null
      // },
    ],
    diagnose: null,
    patient: {
      name: null,
      phone: null,
    },
    phone: null,
    isValid: false,
  };
}
