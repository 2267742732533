import Vue from "vue";

import Inputmask from "inputmask";

const inputmaskPLugin = {
  install(Vue) {
    Vue.directive("inputmask", {
      bind(el, binding) {
        if (el.tagName == "INPUT") {
          Inputmask(binding.value).mask(el);
        } else {
          const inputs = el.getElementsByTagName("INPUT");
          if (inputs?.length) {
            Inputmask(binding.value).mask(inputs[0]);
          }
        }
      },
    });
  },
};

Vue.use(inputmaskPLugin);
