const array = (arr) => (Array.isArray(arr) ? arr : []);
const number = (num) => Number(num) || 0;

export default {
  array,
  number,
  uniarray(arr, key) {
    return array(arr).filter(
      (obj, index, self) => index === self.findIndex((t) => t[key] === obj[key])
    );
  },
  uuid: () => {
    return Date.now().toString(36) + Math.random().toString(36).substr(2);
  },
};
