<script>
import homeHeader from "../components/home-header.vue";

export default {
  name: "Home",

  components: { homeHeader },

  data: () => ({
    //
  }),

  computed: {
    size: (vm) => vm.$store.getters.GET_SIZE,
  },

  mounted() {
    document.body.classList.add("--home");
  },

  destroyed() {
    document.body.classList.remove("--home");
  },
};
</script>

<template>
  <div>
    <v-container>
      <home-header />

      <div style="padding-top: 140px" />

      <v-row>
        <v-col cols="12" md="6">
          <v-row>
            <v-col cols="12" sm="6">
              <div class="-title-lg">100k</div>
              <div style="padding-top: 20px">
                <img
                  v-for="i in 5"
                  :key="i"
                  src="/img/svg/heart.svg"
                  alt=""
                  :class="i != 1 && 'ml-1'"
                />
              </div>

              <div
                style="padding-top: 12px"
                v-text="$t('Electronic prescriptions')"
              />
              <router-link to="/search-drugs" class="-link">
                {{ $t("New recipe") }}
              </router-link>
            </v-col>
            <v-col cols="12" sm="6">
              <div class="-title-lg">4.7</div>
              <div style="padding-top: 20px">
                <img
                  v-for="i in 5"
                  :key="i"
                  src="/img/svg/shape-star.svg"
                  alt=""
                  :class="i != 1 && 'ml-1'"
                />
              </div>

              <div style="padding-top: 12px" v-text="$t('Average rating')" />
              <!-- <router-link to="/search-drugs" class="-link">
                {{ $t("Give feedback") }}
              </router-link> -->
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" md="6">
          <div
            class="in-title -title-sm"
            v-html="
              $t(
                'Trusted by <span>over 25,000</span> happy patients and GoPharm users'
              )
            "
          />

          <!-- <div v-text="$t('Our partners')" class="-partners-title" />

          <div style="padding-top: 24px">
            <img src="/img/svg/parnter-logo.png" alt="" />
          </div> -->
        </v-col>
      </v-row>
    </v-container>

    <div style="padding-top: 140px" />

    <v-divider />

    <div style="padding-top: 140px" />

    <v-container class="pb-0">
      <div
        class="-title in-title text-center"
        style="max-width: 600px; margin: 0 auto"
        v-html="
          $t(
            'The GoPharm <span class=\'text-nowrap\'>E-recipe</span> is easy to use.'
          )
        "
      />

      <div v-if="size.width >= 1264" class="step-wrap">
        <div class="step step-1">
          <div class="imgs">
            <img class="step-head" src="/img/svg/step-search.svg" alt="" />
            <img class="step-arrow" src="/img/svg/arrow.svg" alt="" />
          </div>

          <div class="-title-xs">
            {{ $t("Look for medicines") }}
          </div>

          <div class="-description">
            {{
              $t("Enter the names of drugs in the search and add to the cart")
            }}
          </div>
        </div>
        <div class="step step-2">
          <div class="imgs">
            <img class="step-head" src="/img/svg/step-contact.svg" alt="" />
            <img class="step-arrow" src="/img/svg/arrow-2.svg" alt="" />
          </div>

          <div class="-title-xs">
            {{ $t("Patient data") }}
          </div>

          <div class="-description">
            {{ $t("Add your patient's personal information") }}
          </div>
        </div>
        <div class="step step-3">
          <div class="imgs">
            <img class="step-head" src="/img/svg/step-check.svg" alt="" />
          </div>

          <div class="-title-xs">
            {{ $t("Send to patient") }}
          </div>

          <div class="-description">
            {{
              $t(
                "Send an electronic prescription to the patient's phone number"
              )
            }}
          </div>
        </div>
      </div>
      <div v-else class="pt-10">
        <div class="step step-1">
          <div class="imgs">
            <img class="step-head" src="/img/svg/step-search.svg" alt="" />
          </div>

          <div class="-title-xs">
            {{ $t("Look for medicines") }}
          </div>

          <div class="-description">
            {{
              $t("Enter the names of drugs in the search and add to the cart")
            }}
          </div>
        </div>
        <div class="step step-2 pt-10">
          <div class="imgs">
            <img class="step-head" src="/img/svg/step-contact.svg" alt="" />
          </div>

          <div class="-title-xs">
            {{ $t("Patient data") }}
          </div>

          <div class="-description">
            {{ $t("Add your patient's personal information") }}
          </div>
        </div>
        <div class="step step-3 pt-10">
          <div class="imgs">
            <img class="step-head" src="/img/svg/step-check.svg" alt="" />
          </div>

          <div class="-title-xs">
            {{ $t("Send to patient") }}
          </div>

          <div class="-description">
            {{
              $t(
                "Send an electronic prescription to the patient's phone number"
              )
            }}
          </div>
        </div>
      </div>

      <div style="padding-top: 140px" />

      <div>
        <div
          class="in-title -title text-center"
          style="max-width: 665px; margin: 0 auto"
          v-html="
            $t(
              'Manage your <span class=\'text-nowrap\'> E-Recipes </span> in one place.'
            )
          "
        />

        <img src="/img/manage-recipes.png" alt="" />
      </div>
    </v-container>

    <div>
      <div class="bg-accent-2" />
      <v-container>
        <v-row>
          <v-col cols="12" lg="6">
            <div class="advantages-wrap">
              <div class="-title-lg" v-html="$t('Our advantages')" />

              <div class="advantages-card">
                <div>
                  <img src="/img/svg/advantages-icon-1.svg" alt="" />
                </div>

                <div class="advantages-card-body">
                  <div class="advantages-title" v-text="$t('Conveniently')" />
                  <div class="advantages-description">
                    При создании генератора мы использовали небезизвестный
                  </div>
                </div>
              </div>
              <div class="advantages-card">
                <div>
                  <img src="/img/svg/advantages-icon-2.svg" alt="" />
                </div>

                <div class="advantages-card-body">
                  <div class="advantages-title" v-text="$t('Available')" />
                  <div class="advantages-description">
                    При создании генератора мы использовали небезизвестный
                  </div>
                </div>
              </div>
              <div class="advantages-card">
                <div>
                  <img src="/img/svg/advantages-icon-3.svg" alt="" />
                </div>

                <div class="advantages-card-body">
                  <div class="advantages-title" v-text="$t('Fast')" />
                  <div class="advantages-description">
                    При создании генератора мы использовали небезизвестный
                  </div>
                </div>
              </div>
            </div>
          </v-col>

          <v-col v-if="size.width >= 1264" md="6">
            <img src="/img/our-advantages.png" alt="" />
          </v-col>
        </v-row>
      </v-container>
    </div>

    <div style="padding-top: 100px" />

    <v-footer padless>
      <v-card flat tile width="100%" color="#f9fafb">
        <v-divider></v-divider>

        <v-card-text color="#5f5f5f" class="text-center">
          Gopharm © 2021. {{ $t("All rights reserved.") }}
        </v-card-text>
      </v-card>
    </v-footer>
  </div>
</template>

<style lang="scss" scoped>
.advantages-wrap {
  width: 100%;
  margin: 0 auto;
  padding-top: 20px;

  @media only screen and(min-width:1264px) {
    max-width: 435px;
  }
}

.advantages-card {
  margin-top: 40px;

  @media only screen and(min-width:768px) {
    display: flex;
    align-items: center;

    .advantages-card-body {
      padding-left: 27px;
    }
  }

  .advantages-description {
    padding-top: 5px;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 157%;

    color: #7c8087;
  }
}

.advantages-title {
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 31px;
}

.bg-accent-2 {
  background: linear-gradient(
    0deg,
    rgba(246, 252, 255, 0.0001) 0%,
    #f7f7f7 100%
  );

  height: 140px;
}

.step-wrap {
  display: flex;
  justify-content: space-between;

  max-width: 960px;
  margin: 0 auto;
}

.step {
  max-width: 260px;
  width: 100%;

  &.step-1 {
    .imgs {
      img {
        position: absolute;

        &.step-head {
          left: -49px;
        }

        &.step-arrow {
          top: 75px;
          left: 132px;
        }
      }
    }
  }

  &.step-2 {
    padding-top: 75px;

    .imgs {
      img {
        position: absolute;

        &.step-head {
          left: -49px;
        }

        &.step-arrow {
          top: 20px;
          left: 125px;
        }
      }
    }
  }

  &.step-3 {
    padding-top: 135px;

    .imgs {
      img {
        position: absolute;

        left: -49px;
      }
    }
  }

  .imgs {
    position: relative;

    height: 150px;
    width: 186px;
  }

  .-description {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 140%;

    color: #7c8087;

    padding-top: 18px;
  }
}

.-title {
  font-style: normal;
  font-weight: bold;
  font-size: 48px;
  line-height: 140%;

  letter-spacing: -0.9px;

  @media only screen and(max-width:1264px) {
    font-size: 28px;
  }
}

.-title-lg {
  font-style: normal;
  font-weight: bold;
  font-size: 70px;
  line-height: 100%;
  letter-spacing: -1px;

  @media only screen and(max-width:1264px) {
    font-size: 30px;
  }
}

.-title-sm {
  font-style: normal;
  font-weight: bold;
  font-size: 34px;
  line-height: 140%;
  letter-spacing: -0.6px;

  @media only screen and(max-width:1264px) {
    font-size: 24px;
  }
}

.-title-xs {
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 120%;

  @media only screen and(max-width:1264px) {
    font-size: 18px;
  }
}

.-link {
  color: #258aff;
  font-weight: 300;
  text-decoration: none;
}

.-partners-title {
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 120%;
  color: #7c8087;
  padding-top: 24px;
}
</style>
