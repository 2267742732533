import Vue from "vue";
import VueRouter from "vue-router";
import authGuard from "./service/auth-guard";
import home from "./views/home.vue";
import login from "./views/login.vue";
import register from "./views/register.vue";
import searchDrugs from "./views/search-drugs.vue";
import patientInfo from "./views/patient-info.vue";
import successSent from "./views/success-sent.vue";
// import account from "./views/accout.vue";
// import myAccount from "./components/my-account.vue";
import recipesHistory from "./views/recipes-history.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: home,
  },
  {
    path: "/login",
    component: login,
  },
  {
    path: "/register",
    component: register,
  },
  {
    path: "/search-drugs",
    component: searchDrugs,
    meta: { auth: true },
  },
  {
    path: "/patient-info",
    component: patientInfo,
    meta: { auth: true },
  },
  {
    path: "/success-sent/:name/:phone",
    component: successSent,
    meta: { auth: true },
  },
  {
    path: "/recipes-history",
    name: "recipesHistory",
    component: recipesHistory,
    meta: { auth: true },
  },
  // {
  //   path: "/account",
  //   component: account,
  //   children: [
  //     {
  //       path: "/",
  //       name: "recipes-history",
  //       component: recipesHistory,
  //       meta: { auth: true },
  //     },
  //     {
  //       path: "/my-profile",
  //       component: myAccount,
  //       meta: { auth: true },
  //     },
  //   ],
  // },
  {
    path: "*",
    redirect: "/",
  },
];

const router = new VueRouter({
  routes,
  mode: "history",
});

router.beforeEach(authGuard);

export default router;
