<script>
import { mapActions, mapGetters } from "vuex";

import CardTemplate from "../components/card-template.vue";

export default {
  name: "PatientInfo",

  components: {
    CardTemplate,
  },

  data: () => ({
    isLoading: false,
  }),

  computed: {
    ...mapGetters("send-prescription", ["GET_DATA"]),
  },

  created() {
    if (!this.GET_DATA.isValid) {
      return this.$router.replace("/search-drugs");
    }
  },

  methods: {
    ...mapActions("send-prescription", ["CHANGE", "SEND"]),

    async onSend() {
      if (this.$refs.form.validate()) {
        this.isLoading = true;
        this.SEND({ data: this.GET_DATA }).then(() => {
          this.isLoading = false;
        });
      }
    },

    onChanged({ item, some }) {
      this.CHANGE({ item, some });
    },
  },
};
</script>

<template>
  <card-template
    autoHeight
    noShadow
    :width="935"
    :title="$t('Sending an e-prescription')"
  >
    <div class="text-center" style="padding: 41px 0">
      <img src="/img/svg/progress-2.svg" alt="" />
    </div>

    <v-form
      :readonly="isLoading"
      @submit.prevent="() => false"
      ref="form"
      lazy-validation
      style="max-width: 374px; margin: 0 auto"
    >
      <div class="field-label pt-0">{{ $t("Full name of the patient") }}</div>
      <v-text-field
        autofocus
        autocomplete="off"
        v-model="GET_DATA.patient.name"
        outlined
        required
        :rules="[$rules.required]"
        @blur="onChanged({ some: GET_DATA })"
      />
      <div class="field-label pt-0">{{ $t("Patient phone number") }}</div>
      <v-text-field
        autocomplete="off"
        v-model="GET_DATA.patient.phone"
        outlined
        required
        :rules="[$rules.required, $rules.phone]"
        v-inputmask="$masks.phone"
        @keyup.enter="onSend"
        @blur="onChanged({ some: GET_DATA })"
      />

      <v-btn
        @click="onSend"
        :loading="isLoading"
        color="primary"
        class="w-100 btn-accent"
      >
        {{ $t("Send") }}
      </v-btn>

      <div class="mt-24" />

      <v-btn
        to="/search-drugs"
        color="rgba(124, 128, 135, 0.2)"
        class="btn-accent w-100"
      >
        <span style="color: #7c8087">
          {{ $t("Back") }}
        </span>
      </v-btn>
    </v-form>
  </card-template>
</template>

<style lang="scss" scoped>
.field-label {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 100%;

  padding-top: 24px;
  padding-bottom: 8px;
}
</style>
