<script>
export default {
  props: {
    width: {
      type: Number,
      default: () => 523,
    },
    title: {
      type: String,
      default: () => "",
    },
    description: {
      type: String,
      default: () => "",
    },
    noShadow: {
      type: Boolean,
      default: () => false,
    },
    autoHeight: {
      type: Boolean,
      default: () => false,
    },
  },

  data: () => ({
    //
  }),

  computed: {
    size: (vm) => vm.$store.getters.GET_SIZE,
  },
};
</script>

<template>
  <div
    class="card-wrapper"
    :style="{ height: autoHeight ? 'auto' : `${size.height - 200}px` }"
  >
    <div
      class="card"
      :class="noShadow && 'no-shadow'"
      :style="{ maxWidth: `${width}px` }"
    >
      <div v-if="$props.title" class="card-title" v-text="title" />
      <div
        v-if="$props.description"
        class="card-description"
        v-text="description"
      />

      <div>
        <slot />
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.card-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;

  margin-top: 70px;

  overflow: auto;

  .card {
    width: 100%;

    border-radius: 15px;

    padding: 50px;

    box-shadow: 0px 40px 80px #e6edf4;
    background-color: white;

    &.no-shadow {
      box-shadow: none;
    }

    @media only screen and(max-width:992px) {
      font-size: 10px;
      padding: 0px;
      box-shadow: none;

      padding: 10px;

      .theme--light &.no-shadow {
        background-color: transparent;
      }
    }

    .card-title {
      font-weight: bold;
      font-size: 32px;
      line-height: 100%;

      text-align: center;

      @media only screen and(max-width:992px) {
        font-size: 24px;
      }
    }

    .card-description {
      padding-top: 24px;

      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 150%;

      text-align: center;

      color: #7c8087;

      max-width: 374px;

      margin: 0 auto;
    }
  }
}
</style>
